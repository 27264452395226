import { FC } from 'react';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { sendEmailVerification, UserCredential } from '@firebase/auth';
import { FirebaseError } from '@firebase/app';
import { BaseFormField, createBackendUserService, notice, ToastType, useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import {
  FirebaseSignInFormSchema,
  firebaseSignInFormValidationSchema,
  FirebaseSignUpFormSchema,
} from 'features/auth/formSchema';
import { baseFirebaseAuthErrorHandler, createFirebaseService, OAUTH_PROVIDER_TYPES } from 'features/auth/services';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { AuthProvidersButtons } from '../AuthProvidersButtons';
import { RedirectButton } from '../RedirectButton';
import { BasePasswordFormField } from 'features/common/components/BasePasswordFormField';

export const FirebaseSignInWithEmail: FC = () => {
  const router = useRouter();
  const { setStateData, resetAllStepsData, toStep } = useAuth(state => ({
    setStateData: state.setStateData,
    toStep: state.toStep,
    resetAllStepsData: state.resetAllStepsData,
  }));

  const { getBackendUser } = createBackendUserService();
  const { signInWithEmail, authWithPopupByProvider } = createFirebaseService();

  const {
    register,
    formState: { isDirty, isSubmitting, isValid, errors },
    handleSubmit,
  } = useForm<FirebaseSignInFormSchema>({
    resolver: yupResolver(firebaseSignInFormValidationSchema),
    mode: 'onSubmit',
    defaultValues: { email: '', password: '' },
  });

  const onFirebaseUserConnected = async () => {
    try {
      const backendUser = await getBackendUser();

      if (backendUser) {
        const isAdmin = backendUser?.roles?.includes('ROLE_ADMIN');
        setStateData('backendUser', backendUser);
        setStateData('isAuthorized', true);
        resetAllStepsData();
        router.push(isAdmin ? ROUTES.home : ROUTES.myLegalCases).then(() => {
          notice(ToastType.SUCCESS, `Welcome!`);
        });
      } else {
        toStep(AUTH_FLOW_STEPS.WORK_TYPE);
      }
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const verifyUsersEmailHandler = async (user: UserCredential['user']) => {
    try {
      await sendEmailVerification(user);

      notice(ToastType.WARNING, 'The email address is not verified. Please check your inbox.');
    } catch (error) {
      throw error;
    }
  };

  const onFormSubmitHandler = handleSubmit(async (formData: FirebaseSignUpFormSchema) => {
    const { email, password } = formData;

    try {
      const userCredential = await signInWithEmail({ email, password });
      if (userCredential) {
        userCredential.user.emailVerified
          ? await onFirebaseUserConnected()
          : await verifyUsersEmailHandler(userCredential?.user);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof FirebaseError) {
        baseFirebaseAuthErrorHandler(error);
        return;
      }
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  });

  const onAuthProviderButtonClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const userCredential = await authWithPopupByProvider(event.currentTarget.id as OAUTH_PROVIDER_TYPES);

      if (userCredential) {
        await onFirebaseUserConnected();
      }
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const isNextButtonDisabled = !isValid || isSubmitting || !isDirty;

  return (
    <form onSubmit={onFormSubmitHandler}>
      <BaseFormField
        formInputProps={{ ...register('email'), type: 'email', label: 'Email', fullWidth: true }}
        errorValue={errors['email']?.message}
      />

      <BasePasswordFormField
        formInputProps={{
          ...register('password'),
          label: 'Password',
          fullWidth: true,
        }}
        errorValue={errors?.['password']?.message}
      />
      <MDBox display="flex" justifyContent="flex-end">
        <MDTypography component={Link} to={ROUTES.recoveryPassword} variant="button" color="text" fontWeight="medium">
          Forgot your password?
        </MDTypography>
      </MDBox>

      <AuthProvidersButtons
        isSignIn
        isNextButtonDisabled={isNextButtonDisabled}
        isProviderButtonDisabled={isSubmitting}
        onAuthProviderButtonClickHandler={onAuthProviderButtonClickHandler}
      />
      <RedirectButton routeTo={ROUTES.signUp} questionTitle={`Don't have an account?`} buttonText="Sign up" />
    </form>
  );
};
