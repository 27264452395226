import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { createBackendUserService, notice, ToastType, useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { SignUpPhoneFormSchema, signUpPhoneFormValidationSchema } from 'features/auth/formSchema';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import MDBox from 'components/MDBox';
import { AuthProvidersButtons } from './AuthProvidersButtons';
import { RedirectButton } from './RedirectButton';
import { useCaptchaVerifier } from '../contexts';
import { createFirebaseService, OAUTH_PROVIDER_TYPES } from '../services';
import { PhoneField } from 'features/common/components/PhoneField';
import { isPhoneValid } from 'features/common/helpers/utilities';

type FirebaseSignUpWithEmailProps = {
  acceptAdminInvitationHandler?: () => Promise<void>;
  isSignIn?: boolean;
};

export const FirebaseAuthWithPhone: FC<FirebaseSignUpWithEmailProps> = ({ acceptAdminInvitationHandler, isSignIn }) => {
  const router = useRouter();
  const { resetAllStepsData, setStateData, toStep, setData } = useAuth(state => ({
    setStateData: state.setStateData,
    toStep: state.toStep,
    setData: state.setData,
    resetAllStepsData: state.resetAllStepsData,
  }));

  const { firebaseAppVerifier } = useCaptchaVerifier();

  const { getBackendUser } = createBackendUserService();
  const { authWithPopupByProvider } = createFirebaseService();

  const {
    control,
    setError,
    formState: { isDirty, isValid, errors, isSubmitting },
    handleSubmit,
  } = useForm<SignUpPhoneFormSchema>({
    defaultValues: { phone: '' },
    resolver: yupResolver(signUpPhoneFormValidationSchema),
    mode: 'onChange',
  });

  const onFirebaseUserConnected = async () => {
    try {
      const backendUser = await getBackendUser();

      if (backendUser) {
        const isAdmin = backendUser?.roles?.includes('ROLE_ADMIN');
        setStateData('backendUser', backendUser);
        setStateData('isAuthorized', true);
        resetAllStepsData();
        router.push(isAdmin ? ROUTES.home : ROUTES.myLegalCases).then(() => {
          notice(ToastType.SUCCESS, `Welcome!`);
        });
      } else {
        toStep(AUTH_FLOW_STEPS.WORK_TYPE);
      }
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      const isValid = isPhoneValid(formData.phone);

      if (!isValid) {
        setError('phone', { message: 'Enter a valid phone number' });
        return;
      }

      const confirmationResult = await sendVerificationCodeOnPhoneNumber(formData.phone, firebaseAppVerifier);
      if (confirmationResult) {
        notice(ToastType.SUCCESS, "We've sent verification message to your phone!", { autoClose: false });

        setData({
          currentStep: AUTH_FLOW_STEPS.FIREBASE_AUTH,
          data: { verificationId: confirmationResult.verificationId, phoneNumber: formData.phone },
          activeStep: AUTH_FLOW_STEPS.VERIFY_PHONE,
        });
      }
    } catch (error) {
      console.error(error);
    }
  });

  const { sendVerificationCodeOnPhoneNumber } = createFirebaseService();

  const onAuthProviderButtonClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const userCredential = await authWithPopupByProvider(event.currentTarget.id as OAUTH_PROVIDER_TYPES);

      if (userCredential) {
        await acceptAdminInvitationHandler();

        await onFirebaseUserConnected();
      }
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const isNextButtonDisabled = !isValid || isSubmitting || !isDirty;

  return (
    <form onSubmit={onFormSubmitHandler}>
      <MDBox>
        <Controller
          control={control}
          name="phone"
          render={({ field }) => {
            const onChange = (value: string) => field.onChange(value);
            return (
              <PhoneField {...field} onChange={onChange} label="Phone number" errorValue={errors['phone']?.message} />
            );
          }}
        />

        <AuthProvidersButtons
          isNextButtonDisabled={isNextButtonDisabled}
          isProviderButtonDisabled={isSubmitting}
          onAuthProviderButtonClickHandler={onAuthProviderButtonClickHandler}
          isSignIn={isSignIn}
        />
        <RedirectButton routeTo={isSignIn ? ROUTES.signUp : ROUTES.signIn} />
      </MDBox>
    </form>
  );
};
