import { immer } from 'zustand/middleware/immer';
import { syncTabs } from 'zustand-sync-tabs';
import { BackendUserResponse, ToastType, notice } from 'features/common';
import { UserWorkType } from 'features/auth/types';
import { InvitationAcceptResponse } from 'features/auth/services/invitationsService';
import { usersManagementApiService } from 'features/users-management/UsersManagementApiService';
import { createStore } from '../createStore';

export enum AUTH_FLOW_STEPS {
  FIREBASE_AUTH = 'sign_up',
  WORK_TYPE = 'work_type',
  VERIFY_PHONE = 'verify_phone',
  INVITATION_CODE = 'invitation_code',
  CREATE_USER_PROFILE = 'create_user_profile',
  CREATE_ATTORNEY_PROFILE = 'create_attorney_profile',
  SELECT_LAW_TYPE = 'select_law_type',
  SELECT_LAW_TYPE_STATE = 'select_law_type_state',
}

export type AuthState = {
  isAuthorized: boolean;
  backendUser: BackendUserResponse | null;
  activeStep: AUTH_FLOW_STEPS;
  firebasePhoneAuthData: FirebasePhoneAuthData;
  workType: UserWorkType;
  invitationCode: string;
  invitationUser: InvitationAcceptResponse;
  attorneyProfileAuthData: AttorneyProfileAuthData;
  isNavigationBlocked: boolean;
};

const stepVariant: Partial<Record<AUTH_FLOW_STEPS, keyof AuthState>> = {
  [AUTH_FLOW_STEPS.FIREBASE_AUTH]: 'firebasePhoneAuthData',
  [AUTH_FLOW_STEPS.WORK_TYPE]: 'workType',
  [AUTH_FLOW_STEPS.CREATE_ATTORNEY_PROFILE]: 'attorneyProfileAuthData',
};

type AttorneyProfileAuthData = {
  attorneyProfileId: string;
  barNumber: string;
};

type FirebasePhoneAuthData = {
  verificationId: string;
  phoneNumber: string;
};

type SetDataType = {
  currentStep: AUTH_FLOW_STEPS;
  data: FirebasePhoneAuthData | UserWorkType | AttorneyProfileAuthData;
  activeStep: AUTH_FLOW_STEPS;
};

export type AuthActions = {
  setBackendUser: (backendUser: BackendUserResponse) => void;
  setData: (data: SetDataType) => void;
  toStep: (step: AUTH_FLOW_STEPS) => void;
  setStateData: <K extends keyof AuthState>(key: K, value: AuthState[K]) => void;
  acceptAdminInvitationHandler: (code: string) => Promise<void>;
  resetState: () => void;
  resetAllStepsData: () => void;
};

const { acceptAdminInvitation } = usersManagementApiService();

export const initialState: AuthState = {
  backendUser: null,
  isAuthorized: false,
  activeStep: AUTH_FLOW_STEPS.FIREBASE_AUTH,
  firebasePhoneAuthData: {
    phoneNumber: '',
    verificationId: '',
  },
  workType: UserWorkType.CLIENT,
  invitationCode: '',
  invitationUser: { country: '', email: '', firstName: '', lastName: '', state: '' },
  attorneyProfileAuthData: {
    attorneyProfileId: '',
    barNumber: '',
  },
  isNavigationBlocked: false,
};

export const useAuth = createStore<AuthState & AuthActions>(
  syncTabs(
    set => ({
      ...initialState,
      resetState: () => {
        set(initialState);
      },
      resetAllStepsData: () => {
        set(state => ({
          ...initialState,
          isAuthorized: state.isAuthorized ? true : false,
          backendUser: state.backendUser ? state.backendUser : null,
        }));
      },
      acceptAdminInvitationHandler: async code => {
        try {
          if (!code) return;
          await acceptAdminInvitation(code);
        } catch (error) {
          console.error(error);
          notice(ToastType.ERROR, 'Something went wrong, please try again!');
        }
      },
      setBackendUser: backendUser => {
        set(state => ({
          ...state,
          backendUser,
        }));
      },
      toStep: activeStep => {
        set(state => ({
          ...state,
          activeStep,
        }));
      },
      setData: ({ currentStep, data, activeStep }) =>
        set(state => ({
          ...state,
          [stepVariant[currentStep]]: data,
          activeStep,
        })),

      setStateData: (key, value) => set(state => ({ ...state, [key]: value })),
    }),
    { name: 'auth_sync' }
  ),
  'auth',
  initialState
);
