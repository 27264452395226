import { useEffect, useRef } from 'react';
import { AxiosError } from 'axios';
import { ROUTES } from 'features/common/variables/routes';
import { ToastType, axiosInstance, controller, notice, resetAbortContoller, useRouter, useUser } from 'features/common';
import { resetAllStores } from 'features/store/createStore';

const allowedRoutes = [ROUTES.profile, ROUTES.firmProfile];

export const InterceptorGuard = (): null => {
  const { navigate, pathname } = useRouter();

  const { isNavigationBlocked, backendUser, setStateData, isAttorney, isAuthorized } = useUser();

  const interceptorId = useRef<number | null>(null);

  useEffect(() => {
    if (!isAuthorized && !isAttorney && !backendUser) return;
    interceptorId.current = axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      (error: AxiosError) => {
        if (!allowedRoutes.includes(pathname as ROUTES) && error.response?.status === 402) {
          controller?.abort();
          if (!isNavigationBlocked) {
            setStateData('isNavigationBlocked', true);
          }
          navigate(ROUTES.subscription, { replace: true });
          resetAbortContoller();
        }
        switch (error?.response?.status) {
          case 401:
            if (pathname === ROUTES.lockScreen) {
              return Promise.reject(error);
            }
            controller?.abort();

            notice(ToastType.WARNING, 'Token is expired!');

            navigate(ROUTES.signIn, { replace: true });
            resetAllStores();
            resetAbortContoller();

          default:
            return Promise.reject(error);
        }
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptorId.current as number);
    };
  }, [navigate]);

  return null;
};
