import { useCallback } from 'react';
import { resetAllStores } from 'features/store/createStore';
import Cookies from 'js-cookie';
import { useAuth, useNotificationsCenter } from 'features/store';
import { firebaseAuth, unregisterSW } from 'features/auth';
import { shallow } from 'zustand/shallow';
import { useFirebaseUser } from 'features/auth/contexts/FirebaseUserContextProvider';
import { signInWithCustomToken } from 'firebase/auth';
import { createBackendUserService, LAST_MOBILE_ALERT_POPUP_TIME_KEY, RetrieveTokenPayload } from '../../common';

export const useUser = () => {
  const { getBackendUser, logoutBackendUser, retrieveToken } = createBackendUserService();
  const { accessToken } = useFirebaseUser();

  const { isNavigationBlocked, setBackendUser, setStateData, isAuthorized, backendUser } = useAuth(
    state => ({
      backendUser: state.backendUser,
      setBackendUser: state.setBackendUser,
      setStateData: state.setStateData,
      isAuthorized: state.isAuthorized,
      isNavigationBlocked: state.isNavigationBlocked,
    }),
    shallow
  );

  const isAdmin = backendUser?.roles?.includes('ROLE_ADMIN');
  const isAttorney = backendUser?.roles?.includes('ROLE_ATTORNEY');
  const isClient = backendUser?.roles?.includes('ROLE_CLIENT');
  const backendUserFirebaseId = backendUser?.firebaseUser;
  const isClioActive = backendUser?.integration?.clioActive;
  const isDocuSignActive = backendUser?.integration?.docuSignActive;
  const isAdobeSignActive = backendUser?.integration?.adobeSignActive;
  const isPinCodeActive = backendUser?.userMeta?.pinCodeEnabled;

  //TODO conference hack
  const isCanSendRepresentationRequest = backendUser?.attorneyProfile?.canSendRepresentationRequest;

  const firebaseNotificationToken = useNotificationsCenter().pushNotificationData?.firebaseToken;

  const logout = useCallback(async () => {
    await logoutBackendUser([firebaseNotificationToken]);
    await firebaseAuth.signOut();
    resetAllStores();
    await unregisterSW();

    Cookies.remove(LAST_MOBILE_ALERT_POPUP_TIME_KEY);
  }, [firebaseNotificationToken]);

  const retrieveTokenHandler = useCallback(async (payload: RetrieveTokenPayload) => {
    try {
      const { data } = await retrieveToken(payload);

      await signInWithCustomToken(firebaseAuth, data?.token);
    } catch (error) {
      console.error(error);
      resetAllStores();
      await firebaseAuth.signOut();
      await unregisterSW();
    }
  }, []);

  const connectBackendUserHandler = useCallback(async () => {
    if (!accessToken) return;

    const backendUser = await getBackendUser();

    if (backendUser) {
      setBackendUser(backendUser);
    } else {
      setBackendUser(null);
    }
  }, [backendUser]);

  return {
    retrieveTokenHandler,
    logout,
    connectBackendUserHandler,
    setStateData,
    isNavigationBlocked,
    isAdmin,
    isAttorney,
    isClient,
    backendUserFirebaseId,
    backendUser,
    isAuthorized,
    isClioActive,
    isDocuSignActive,
    isPinCodeActive,
    isAdobeSignActive,
    firebaseNotificationToken,
    isCanSendRepresentationRequest,
  };
};
